import { createSlice } from '@reduxjs/toolkit';
import { getNotifications } from './notificationThunk';
const initialState = {
    data: [],
    oneData: undefined,
    loading: false,
    error: null,
    total: 0,
};
const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        markAllAsSeen: (state) => {
            state.data = state.data.map((el) => {
                el.seen = true;
                return el;
            });
        },
        markOneAsSeen: (state, { payload }) => {
            state.data = state.data.map((el) => {
                if (el?._id === payload?.id)
                    el.seen = true;
                return el;
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotifications.pending, (state, actions) => {
            state.error = false;
            state.loading = true;
        })
            .addCase(getNotifications.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.data?.docs;
        })
            .addCase(getNotifications.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        });
    },
});
// Reducer
export default slice.reducer;
// Actions
export const { markAllAsSeen, markOneAsSeen } = slice.actions;
